import React from "react"

import Analytics from "../../assets/images/hero-analytics.svg"
import Frontpage from "../../assets/images/hero-homepage.svg"
import Insights from "../../assets/images/hero-insights.svg"
import Partners from "../../assets/images/hero-partners.svg"
import Team from "../../assets/images/hero-team.svg"
import LargeEnterprise from "../../assets/images/hero-large-enterprise.svg"
import MidEnterprise from "../../assets/images/hero-mid-enterprise.svg"
import SmallEnterprise from "../../assets/images/hero-small-enterprise.svg"
import Screen from "../../assets/images/hero-partial-screen.svg"
import Infinity from "../../assets/images/hero-infinity.svg"
import Webinar from "../../assets/images/hero-webinar.svg"

import styles from "./heroImage.module.scss"

const HeroImage = (props) => {
  switch (props.type) {
    case "Default":
      return <img className={`${props.className}`} src={Frontpage} width="500px" alt="People stacking various screens showing different graphs" />

    case "frontpage":
      return <img className={`${props.className}`} src={Frontpage} width="500px" alt="People stacking various screens showing different graphs" />

    case "Partial Screen":
      return <img className={`${props.className} ${styles.about}`} src={Screen} width="700px" alt="Screen showing graphs and statistics" />

    case "Infinity":
      return <img className={`${props.className} ${styles.about}`} src={Infinity} width="700px" alt="Screen showing graphs and statistics" />

    case "Analyst Insights":
      return <img className={props.className} src={Insights} width="500px" alt="Screen showing graphs and statistics" />

    case "Analytics":
      return <img className={props.className} src={Analytics} width="500px" alt="Screen showing graphs and statistics" />

    case "Team":
      return <img className={`${props.className} ${styles.team}`} src={Team} width="1200px" alt="People jumping for joy with arms up" />

    case "Partners":
      return <img className={`${props.className} ${styles.partners}`} src={Partners} width="460px" alt="A man and and a woman shake hands" />

    case "Large Enterprise":
      return <img className={props.className} src={LargeEnterprise} width="560px" alt="Screen showing graphs and statistics" />

    case "Mid Enterprise":
      return <img className={props.className} src={MidEnterprise} width="380px" alt="Screen showing graphs and statistics" />

    case "Small Enterprise":
      return <img className={props.className} src={SmallEnterprise} width="320px" alt="Screen showing graphs and statistics" />

    case 'Webinar':
      return <img className={props.className} src={Webinar} alt="Screen showing graphs and statistics" />

    default:
      return null
  }
}

export default HeroImage
