import React from "react"

import Recruitment from "../../../assets/images/hero-recruitment.svg"
import Performance from "../../../assets/images/hero-performance.svg"
import Compensation from "../../../assets/images/hero-compensation.svg"
import Succession from "../../../assets/images/hero-succession.svg"
import OrgCharting from "../../../assets/images/hero-org-charting.svg"
import Learning from "../../../assets/images/hero-learning.svg"
import Onboarding from "../../../assets/images/hero-onboarding.svg"
import LearningAnalytics from "../../../assets/images/hero-analytics.svg"
import ContentAuthoring from "../../../assets/images/hero-authoring.svg"
import LXP from "../../../assets/images/hero-lxp.svg"
import LMS from "../../../assets/images/hero-lms.svg"
import LargeEnterprise from "../../../assets/images/hero-large-enterprise.svg"
import MidEnterprise from "../../../assets/images/hero-mid-enterprise.svg"
import Career from "../../../assets/images/hero-career.svg"
import Scheduling from "../../../assets/images/hero-scheduling.svg"
import CRM from "../../../assets/images/hero-crm.svg"
import Mobility from "../../../assets/images/hero-mobility.svg"
import Sourcing from "../../../assets/images/hero-sourcing.svg"
import TalentMobility from "../../../assets/images/hero-talent-mobility.svg"
import CareerPathing from "../../../assets/images/hero-career-pathing.svg"
import InternalMobility from "../../../assets/images/hero-internal-mobility.svg"
import ProjectsMarketplace from "../../../assets/images/hero-projects-marketspace.svg"
import MentorsMarketplace from "../../../assets/images/hero-workforce-analytics.svg"
import WorkforceAnalytics from "../../../assets/images/hero-talent-mobility.svg"


import styles from "./heroImage.module.scss"

const HeroImage = (props) => {
  switch (props.product) {
    case 'Recruitment':
      return <img className={`${props.className} ${styles.recruitment}`} src={Recruitment} alt="People looking at candidate profiles" />

    case 'Performance':
      return <img className={`${props.className} ${styles.performance}`} src={Performance} alt="2 people hold giant cogs while others climb them" />

    case 'Compensation':
      return <img className={`${props.className} ${styles.compensation}`} src={Compensation} alt="Man and woman shake hands in front of charts" />

    case 'Succession':
      return <img className={`${props.className} ${styles.succession}`} src={Succession} alt="People stand in formation between screenshots of PeopleFluent products" />

    case 'Org Charting':
      return <img className={`${props.className} ${styles.orgCharting}`} src={OrgCharting} alt="People moving giant puzzle pieces into position" />

    case 'Learning':
      return <img className={`${props.className} ${styles.learning}`} src={Learning} alt="People sitting on a giant stack of books doing various activities" />

    case 'Onboarding':
      return <img className={`${props.className} ${styles.onboarding}`} src={Onboarding} alt="Man and woman discussing a video on a large screen" />

    case 'Learning Analytics':
      return <img className={`${props.className} ${styles.learningAnalytics}`} src={LearningAnalytics} alt="People physically rearranging graphs on a large screen" />

    case 'Content Authoring':
      return <img className={`${props.className} ${styles.contentAuthoring}`} src={ContentAuthoring} alt="A man points at oversized graphs with a giant pen while a woman works on a laptop" />

    case 'LXP':
      return <img className={`${props.className} ${styles.lxp}`} src={LXP} alt="A woman points at an oversized screen while a man and a woman work on laptops" />

    case 'LMS':
      return <img className={`${props.className} ${styles.lms}`} src={LMS} alt="A woman shares an idea with a coworker while a man and a woman work on laptops" />

    case 'Mid Enterprise':
      return <img className={`${props.className} ${styles.midEnterprise}`} src={MidEnterprise} alt="3 People sit around a desk in front of windows looking out onto a city" />

    case 'Large Enterprise':
      return <img className={`${props.className} ${styles.largeEnterprise}`} src={LargeEnterprise} alt="4 People sit around a meeting table in front of windows looking out onto a city skyline" />

    case 'Career':
      return <img className={`${props.className} ${styles.career}`} src={Career} alt="4 People sit around a meeting table in front of windows looking out onto a city skyline" />

    case 'Sourcing':
      return <img className={`${props.className} ${styles.sourcing}`} src={Sourcing} alt="4 People sit around a meeting table in front of windows looking out onto a city skyline" />

    case 'CRM':
      return <img className={`${props.className} ${styles.crm}`} src={CRM} alt="4 People sit around a meeting table in front of windows looking out onto a city skyline" />

    case 'Scheduling':
      return <img className={`${props.className} ${styles.scheduling}`} src={Scheduling} alt="4 People sit around a meeting table in front of windows looking out onto a city skyline" />

    case 'Mobility':
      return <img className={`${props.className} ${styles.mobility}`} src={Mobility} alt="4 People sit around a meeting table in front of windows looking out onto a city skyline" />

    case 'Talent Mobility':
      return <img className={`${props.className} ${styles.talentMobility}`} src={TalentMobility} alt="4 People sit around a meeting table in front of windows looking out onto a city skyline" />    

    case 'Career Pathing':
      return <img className={`${props.className} ${styles.talentMobility}`} src={CareerPathing} alt="A man standing in front of a carrers listing board" />    

    case 'Internal Mobility':
      return <img className={`${props.className} ${styles.talentMobility}`} src={InternalMobility} alt="A man sitting on a chair with a laptop on his knees" />    

    case 'Projects Marketplace':
      return <img className={`${props.className} ${styles.talentMobility}`} src={ProjectsMarketplace} alt="A man standing in front of a projects listing board" />    

    case 'Mentors Marketplace':
      return <img className={`${props.className} ${styles.talentMobility}`} src={MentorsMarketplace} alt="A woman standing in front of a board" />    

      case 'Workforce Analytics':
      return <img className={`${props.className} ${styles.talentMobility}`} src={WorkforceAnalytics} alt="3 men standing on books" />    

    default:
      return null
  }
}

export default HeroImage
