import React from "react"
import { connect } from "react-redux"
import cx from "classnames"

import PlayButton from "../../assets/svg/play-button.svg"
import SimpleH2 from "../components/block-content/simpleH2"
import CTAButton from "../components/ctaButton"
import HeroImage from "../components/heroImage"
import ProductHeroImage from "../templates/products/heroImage"
import { showVideoModal } from "../redux/actions/modalActions"

import styles from "./hero.module.scss"

const Hero = props => {
  const handleClick = e => {
    e.preventDefault()
    props.dispatch(
      showVideoModal({ isOpen: !props.isOpen, url: props.heroVideo })
    )
  }

  const withoutImage =
    !props.customIllustration &&
    (!props.illustration || props.illustration === "No Illustration")

  const heroSectionClass = cx(styles.heroSection, {
    [styles.heroSection__pink]: props.background === "pink",
  })

  const heroMainSectionClass = cx(styles.heroMainSection, {
    [styles.heroMainSection__withoutImage]: withoutImage,
    [styles.heroMainSection__inColumn]: props.layout === "column",
    [styles.heroMainSection__pink]: props.background === "pink",
  })

  const heroTagline2Class = cx(styles.heroTagline2, {
    [styles.heroTagline2__purple]: props.background !== "pink",
  })

  const heroClass = cx(styles.heroCtas, {
    [styles.heroCtas__multiple]: props.ctas?.link?.length > 2,
  })

  return (
    <section className={heroSectionClass}>
      <div className={heroMainSectionClass}>
        <div className={styles.heroContent}>
          <h1>{props.heading}</h1>
          {props.tagline && <SimpleH2 blocks={props.tagline} />}
          {props.ctas && (
            <div className={heroClass}>
              {props.ctas.link.map(cta => (
                <CTAButton key={cta._key} blocks={cta} />
              ))}
            </div>
          )}
        </div>
        {!withoutImage && (
          <div className={styles.heroImage}>
            {props.customIllustration ? (
              <div className={styles.customIllustrationContainer}>
                <img
                  className={styles.heroImageIllustration}
                  src={props.customIllustration.image.asset.url}
                  alt={props.customIllustration.alt}
                  style={{ maxHeight: '450px' }}
                />
                {props.heroVideo && (
                  <div
                    role="button"
                    onClick={handleClick}
                    className={styles.playButtonContainer}
                  >
                    <PlayButton />
                  </div>
                )}
              </div>
            ) : props.illustration ? (
              props.isProduct ? (
                <ProductHeroImage
                  className={styles.heroImageIllustration}
                  product={props.illustration}
                />
              ) : (
                <HeroImage
                  className={styles.heroImageIllustration}
                  type={props.illustration}
                />
              )
            ) : null}
          </div>
        )}
      </div>

      {props.tagline2 && (
        <div className={heroTagline2Class}>
          <SimpleH2 blocks={props.tagline2} />
        </div>
      )}
    </section>
  )
}

export default connect()(Hero)
