import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../modules/layout"
import Hero from "../modules/hero"
import PageBuilder from "../components/page-builder"

export const query = graphql`
  query ($slug: String) {
    sanitySolution(slug: {current: {eq: $slug}}) {
      title
      slug {current}
      _rawHero
      _rawContent(resolveReferences: {maxDepth: 10})
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

export default (props) => {
  const page = props.data.sanitySolution
  const title = page.title
  const customTitle = page.seo && page.seo.metaTitle ? page.seo.metaTitle : null
  const description = page.seo && page.seo.description ? page.seo.description : null
  const image = page.seo && page.seo.openGraphImage && page.seo.openGraphImage.asset !== null && page.seo.openGraphImage.asset !== null ? page.seo.openGraphImage.asset.url : null
  return (
    <>
      <Layout>
        <Helmet>
          {page.seo && page.seo.noIndex && <meta name="robots" content="noindex" />}
        </Helmet>
        <SEO
          title={title}
          description={description}
          image={image}
          customTitle={customTitle}
        />
        {page._rawHero && <Hero {...page._rawHero} tagline={page._rawHero.tagline} ctas={page._rawHero.ctas} />}
        {page._rawContent && <PageBuilder blocks={page._rawContent} />}
      </Layout>
    </>
  )
}

